<template>
  <div class="lstIzin">
    <button
      v-show="isTrue == true"
      class="btn btn-danger btn-sm float-start my-3 mx-2"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#timeabsenpulang"
      @click.prevent="showModalsBs('', 'tambah')"
    >
      Tambah Wish
    </button>
    <br />
    <div class="col-12 py-5">
      <div class="list-group">
        <div v-for="(item, index) in listOfeo" :key="index" class="py-1 px-2">
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                Pengajuan Tahun {{ formatYear(item.created_at) }}
              </h5>
              <button
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#timeabsenpulang"
                @click.prevent="showModalsBs(item.autono, 'edit', item)"
              >
                Ubah Data
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-block': btnDisabled }"
          :disabled="btnDisabled"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
    <div
      class="modal fade"
      id="timeabsenpulang"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="timeabsenpulangLabel"
      aria-hidden="true"
      :class="{ 'show d-block mt-0 px-0': showModals, '': !showModals }"
      v-bind:style="showModals ? 'background-color: #0000009c' : ''"
      v-bind:role="showModals ? 'dialog' : ''"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="timeabsenpulangLabel">Ubah Data</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="hideModalsBs()"
            ></button>
          </div>
          <div class="modal-body">
            <form
              @submit.prevent="updateData(editValue.autono)"
              ref="fEditable"
            >
              <dl class="row g-2">
                <dt>
                  Range Harga Max ({{ formatRupiah(max_range) }}): ({{
                    formatRupiah(editValue.range_harga)
                  }})
                </dt>
                <dd>
                  <input
                    class="form-control"
                    type="range"
                    id="vol"
                    name="vol"
                    min="0"
                    step="50000"
                    :max="max_range"
                    v-model="editValue.range_harga"
                  />
                </dd>
                <dt>Foto Terkait :</dt>
                <dd>
                  <button class="btn btn-primary" type="button" @click="upload">
                    Upload
                  </button>
                  <div class="col-12">
                    <image-compressor
                      class="compressor d-none"
                      :done="getFiles"
                      :scale="scale"
                      :quality="quality"
                      ref="compressor"
                    ></image-compressor>
                    <span>{{ filename }}</span>
                  </div>
                  <small>format file : <code>jpg, png, jpeg</code></small>
                </dd>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    v-model="editValue.link"
                  />
                </dd>
                <dt>Keterangan :</dt>
                <dd>
                  <textarea
                    class="form-control"
                    cols="30"
                    rows="10"
                    v-model="editValue.keterangan"
                    required
                  ></textarea>
                </dd>
              </dl>
              <button type="submit" class="btn btn-primary mt-1 btn-block">
                Simpan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imageCompressor from "./Compressor/vue-image-compressor.vue";
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
export default {
  components: {
    imageCompressor,
  },
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfeo: [],
      dataEmpty: true,
      jamSelesai: null,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      tglsaatini: new Date().toISOString().slice(0, 10),
      headers: "",
      dataView: [],
      editValue: {
        autono: "",
        range_harga: "",
        link: "",
        keterangan: "",
      },
      total: "",
      isTrue: false,
      max_range: localStorage.getItem("range_kado"),
      file: "",
      base64: null,
      filename: null,
      mimetype: null,
      compressed: {},
      scale: 50,
      quality: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    upload() {
      let compressor = this.$refs.compressor.$el;
      compressor.click();
    },
    getFiles(obj) {
      this.compressed = obj.compressed.base64;
      this.mimetype = obj.compressed.type;
      this.filename = obj.compressed.name;
      this.ukuran = obj.compressed.file.size;
      this.quality = 50;
    },
    convertToHMS(total) {
      let hours = Math.floor(total / 3600);
      let minutes = Math.floor((total % 3600) / 60);
      let seconds = total % 60;

      let timeString = "";
      if (hours > 0) {
        timeString += `${hours} Jam ${minutes} Menit ${seconds} Detik`;
      } else if (minutes > 0) {
        timeString += `${minutes} Menit, ${seconds} Detik`;
      } else {
        timeString += `${seconds} Detik`;
      }

      return timeString;
    },
    showModalsBs(autono, status, ress) {
      if (status == "tambah") {
        // window.location.href = "/wish";
        // this.$router.push("/wish");
        this.$router.replace({ path: "/wish" });
      } else {
        this.showModals = true;
        try {
          axios
            .post(
              this.UrlApi + "pegawai/detilwish",
              {
                autono: autono,
              },
              { headers: this.headers }
            )
            .then((res) => {
              this.editValue.autono = autono;
              this.editValue.link = res.data.data.link;
              this.editValue.keterangan = res.data.data.keterangan;
              this.editValue.range_harga = res.data.data.range_harga;
              this.filename = ress.file_upload;
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response");
              } else if (err.request) {
                console.log("error request");
              } else {
                console.log("error");
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    hideModalsBs() {
      this.showModals = false;
    },
    hitungSelisih(startTime, endTime) {
      let start = moment(startTime, "HH:mm:ss");
      let end = moment(endTime, "HH:mm:ss");

      // Jika waktu akhir lebih awal dari waktu mulai, tambahkan satu hari ke waktu akhir
      if (end.isBefore(start)) {
        end.add(1, "day");
      }

      // Hitung selisih waktu dalam durasi
      let duration = moment.duration(end.diff(start));

      // Ekstrak jam dan menit dari durasi
      let hours = Math.floor(duration.asHours());
      let minutes = duration.minutes();

      return `${hours} Jam ${minutes} menit`;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatYear(date) {
      return moment(date).format("YYYY");
    },
    formatRupiah(value) {
      return "Rp " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "pegawai/listwish?page=" + pages,
            {
              id_pegawai: localStorage.getItem("id"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              if (res.data.data.length > 0) {
                this.page += 1;
                res.data.data.data.forEach((element) => {
                  Array.prototype.push.apply(this.listOfeo, element);
                });
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    updateData(autono) {
      try {
        axios
          .post(
            this.UrlApi + "pegawai/updateWish",
            {
              autono: autono,
              link: this.editValue.link,
              range_harga: this.editValue.range_harga,
              keterangan: this.editValue.keterangan,
              file: this.compressed,
              filename: this.filename,
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              Swal.fire({
                icon: "success",
                title: "",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });

              this.total = res.data.data;
              this.hideModalsBs();
              //   window.location.reload();
              this.loadData();
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
            this.empty = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    loadData() {
      this.listOfeo = [];
      try {
        axios
          .post(
            this.UrlApi + "pegawai/listwish?page=",
            {
              id_pegawai: localStorage.getItem("id"),
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.dataEmpty = res.data.data.data.length > 0 ? true : false;
              res.data.data.data.forEach((element) => {
                this.listOfeo.push(element);
                let tahun = this.formatYear(element.created_at);
                let current_tahun = new Date().getFullYear();
                if (current_tahun == tahun) {
                  this.isTrue = false;
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    checkBirthday() {
      const birthdayString = localStorage.getItem("birthdate");
      const birthdayDate = new Date(birthdayString);

      // Dapatkan tanggal hari ini
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set jam ke 00:00:00

      // Ubah tahun ulang tahun ke tahun ini
      birthdayDate.setFullYear(today.getFullYear());

      // Cek jika ulang tahun tahun ini sudah lewat
      if (birthdayDate < today) {
        console.log("Hari ulang tahun sudah lewat tahun ini.");
        birthdayDate.setFullYear(today.getFullYear() + 1); // Set ke tahun depan
      } else {
        console.log("Hari ulang tahun belum lewat.");
      }

      // Tanggal 1 bulan sebelum ulang tahun
      const oneMonthBefore = new Date(birthdayDate);
      oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

      // Tanggal 1 minggu sebelum ulang tahun
      const oneDayBefore = new Date(birthdayDate);
      oneDayBefore.setDate(oneDayBefore.getDate() - 2);

      // Cek apakah hari ini di antara satu bulan sebelum hingga satu minggu sebelum ulang tahun
      // if (today >= oneMonthBefore && today <= oneDayBefore) {
      //   this.isTrue = true;
      //   console.log("Reminder: Ulang tahun kurang dari sebulan lagi!");
      // } else if (today < oneMonthBefore) {
      //   this.isTrue = false;
      //   console.log("Belum waktunya reminder.");
      // } else {
      //   this.isTrue = false;
      //   console.log("Reminder sudah lewat.");
      // }
      if (birthdayDate > today) {
        this.isTrue = true;
        console.log("Reminder: Ulang tahun belum terjadi!");
      } else {
        this.isTrue = false;
        console.log("Reminder sudah lewat.");
      }
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    this.loadData();
    this.checkBirthday();
  },
};
</script>
