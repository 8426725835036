<template>
  <div class="bg-dark position-absolute h-100 drw" v-if="!drawVisible">
    <div class="sticky-top">
      <div class="d-block">
        <button
          v-on:click="toggle"
          type="button"
          class="btn btn-dark float-end text-white w-100"
        >
          X
        </button>
      </div>
    </div>
    <MenuSide />
  </div>
  <nav class="navbar py-3 position-absolute">
    <button
      v-on:click="toggle"
      type="button"
      class="btn btn-link float-end text-white text-decoration-none"
    >
      <img src="../../public/img/home/menu.png" alt="back" width="30" />
    </button>
    <a href="/faq" class="text-decoration-none mx-3">
      <img src="../../public/img/home/faq.png" alt="guide" width="40" />
    </a>
    <a
      href="/list-wish"
      class="text-decoration-none mx-3"
      v-show="isTrue == true"
    >
      <img src="../../public/img/home/gift.png" alt="guide" width="40" />
    </a>
  </nav>
</template>
<script>
import MenuSide from "./MenuSide.vue";
import axios from "axios";
// import LoginPage from "./components/LoginPage.vue";

export default {
  data() {
    return {
      drawVisible: true,
      isTrue: false,
      headers: "",
      stsWish: 0,
    };
  },
  components: {
    MenuSide,
    // LoginPage,
  },
  methods: {
    toggle() {
      this.drawVisible = !this.drawVisible;
    },
    checkBirthday() {
      console.log("Checking...");
      const birthdayString = localStorage.getItem("birthdate");
      const birthdayDate = new Date(birthdayString);

      // Dapatkan tanggal hari ini
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set jam ke 00:00:00

      // Ubah tahun ulang tahun ke tahun ini
      birthdayDate.setFullYear(today.getFullYear());

      // Cek jika ulang tahun tahun ini sudah lewat
      if (birthdayDate < today) {
        console.log("Hari ulang tahun sudah lewat tahun ini.");
        birthdayDate.setFullYear(today.getFullYear() + 1); // Set ke tahun depan
      } else {
        console.log("Hari ulang tahun belum lewat.");
      }

      // Tanggal 1 bulan sebelum ulang tahun
      const oneMonthBefore = new Date(birthdayDate);
      oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

      // Tanggal 1 minggu sebelum ulang tahun
      const oneDayBefore = new Date(birthdayDate);
      oneDayBefore.setDate(oneDayBefore.getDate() - 2);

      // Cek apakah hari ini di antara satu bulan sebelum hingga satu minggu sebelum ulang tahun
      // if (today >= oneMonthBefore && today <= oneDayBefore) {
      //   this.isTrue = true;
      //   console.log("Reminder: Ulang tahun kurang dari sebulan lagi!");
      // } else if (today < oneMonthBefore) {
      //   this.isTrue = false;
      //   console.log("Belum waktunya reminder.");
      // } else {
      //   this.isTrue = false;
      //   console.log("Reminder sudah lewat.");
      // }
      if (birthdayDate > today) {
        this.isTrue = true;
        console.log("Reminder: Ulang tahun belum terjadi");
      } else {
        this.isTrue = false;
        console.log("Reminder sudah lewat.");
      }
    },
    getBirth() {
      try {
        axios
          .post(
            this.UrlApi + "pegawai/dateBirth",
            {
              id_pegawai: localStorage.getItem("id"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data != null) {
              localStorage.setItem("birthdate", res.data.data[0].tanggal_lahir);
              localStorage.setItem("range_kado", res.data.data[0].range_kado);
              this.stsWish = res.data.data[0].status_tampil_kado;
              if (res.data.data[0].status_tampil_kado == 1) {
                this.isTrue = true;
              } else {
                this.isTrue = false;
              }

              if (this.stsWish == 1) {
                this.checkBirthday();
              }
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };
    this.getBirth();
  },
};
</script>
