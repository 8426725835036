<template>
  <div class="form-sakit">
    <div class="overlay" v-show="loadingProg">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit" class="row g-3" ref="fUbahLokasi">
      <div class="col-12">
        <label for="vol"
          >Range Harga (Max : {{ formatRupiah(max_range) }}):
          {{ formatRupiah(current_range) }}</label
        >
        <input
          class="form-control"
          type="range"
          id="vol"
          name="vol"
          min="0"
          step="50000"
          :max="max_range"
          v-model="current_range"
        />
      </div>
      <label for="vol">Foto Terkait</label>
      <button class="btn btn-primary mt-4" type="button" @click="upload">
        Upload
      </button>
      <div class="col-12">
        <image-compressor
          class="compressor d-none"
          :done="getFiles"
          :scale="scale"
          :quality="quality"
          ref="compressor"
        ></image-compressor>
        <span>{{ filename }}</span>
      </div>
      <small>format file : <code>jpg, png, jpeg</code></small>

      <div class="col-12">
        <label>Link terkait</label>
        <input
          type="text"
          class="form-control"
          v-model="linkTerkait"
          required
        />
      </div>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Ajukan</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import imageCompressor from "./Compressor/vue-image-compressor.vue";
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  components: {
    imageCompressor,
  },
  data: function () {
    return {
      keterangan: null,
      id: parseInt(localStorage.getItem("id")),
      loadingProg: false,
      linkTerkait: "",
      max_range: localStorage.getItem("range_kado"),
      current_range: 0,
      file: "",
      base64: null,
      filename: null,
      mimetype: null,
      compressed: {},
      scale: 50,
      quality: 0,
    };
  },
  methods: {
    upload() {
      let compressor = this.$refs.compressor.$el;
      compressor.click();
    },
    getFiles(obj) {
      this.compressed = obj.compressed.base64;
      this.mimetype = obj.compressed.type;
      this.filename = obj.compressed.name;
      this.ukuran = obj.compressed.file.size;
      this.quality = 50;
    },
    formatRupiah(value) {
      return "Rp " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async submit() {
      if (this.current_range == 0) {
        Swal.fire({
          icon: "info",
          title: "Info!",
          text: "Silahkan isi range harga",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.loadingProg = true;

        try {
          axios
            .post(
              this.UrlApi + "pegawai/storeWish",
              {
                id_pegawai: localStorage.getItem("id"),
                link: this.linkTerkait,
                keterangan: this.keterangan,
                range_harga: this.current_range,
                file: this.compressed,
                filename: this.filename,
              },
              { headers: headers }
            )
            .then((res) => {
              console.log(res.data.data);
              if (res.data.success && res.data.success !== null) {
                this.loadingProg = false;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil!",
                  text: "Permintaan berhasil disimpan",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.keterangan = "";
                this.linkTerkait = "";
                this.current_range = 0;

                // window.location.href = "/list-wish";
                // this.$router.push("/list-wish");
                this.$router.replace({ path: "/list-wish" });
              } else {
                this.loadingProg = false;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response ");
                this.loadingProg = false;
              } else if (err.request) {
                console.log("error request");
                this.loadingProg = false;
              } else {
                console.log("error");
                this.loadingProg = false;
              }
            });
        } catch (error) {
          console.log(error);
          this.loadingProg = false;
        }
      }
    },
  },
};
</script>
